#background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: -1;

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        object-fit: cover;
    }
}