div.modal div.header {
    font-size: 1.6em !important;

    i {
        font-size: 1.2em !important;
        margin-right: 15px;
    }
}

form div .field .field {
    width: 100%;
}

.ui.compact.icon.button {
    padding: 5px !important;
}

.ui.modal {
    border-radius: 10px !important;
    overflow: hidden;
}

.ui.dimmer {
    background-color: rgba(35, 51, 74, 0.85) !important;
}

.ui.modal .date-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 0px;
        margin-bottom: 15px;
    }
}

.intensity-marker {
    display: flex;
    justify-content: space-between;
    padding-left: 7px;
}

.slider-wrap {
    position: relative;
}

.slider-picker {
    max-width: 85%;
    display: block;
    position: relative;

    * {
        position: relative;
    }
}

.slider-swatch {
    width: 10%;
    margin-left: 5%;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
}

.btn-swatch {
    outline: none !important;
    margin-left: 5px;
    display: inline-block;
    position: absolute;
    left: 30px;
    bottom: 47px;
}

#arrangeRowsModal {
    position: fixed;
    right: 0px;
    height: auto;
    max-height: 100%;
    margin-top: 0px;
    transform: translateY(-50%);
    top: 50%;
    overflow: auto;
    max-width: 100%;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        h4 { 
            margin: 0px;
        }

        .close.icon {
            position: absolute;
            right: -5px;
            font-size: 0.75em !important;
            top: 10px;
            cursor: pointer;
        }
    }

    .segment .key-wrap {
        display: flex;
        flex-direction: row;
        cursor: move;

        .circle {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 10px;
        }

        h5 {
            margin-top: 0px;
        }
    }   
    
    .dropzone {
        height: 0px !important;
        padding: 0px !important;
        transition: 0.3s all;
        border: 1px dashed transparent !important;

        &.dragging {
            height: 25px !important;
            border: 1px dashed rgba(128, 119, 105, 0.15) !important;
            background-color: lightgrey;
        }

        &.dragOver {
            height: 50px !important;
        }
    }  
}

// Share View Modal
div.modal.shared {
    * {
        opacity: 1 !important;
    }

    i.close,
    i.calendar {
        display: none !important;
    }

    .slider-wrap {
        height: 50px !important;

        .slider-swatch {
            width: 100% !important;
        }
    }
}

.zoom-level-dropdown {
    h5 {
        margin-bottom: 3px;
    }

    .visible.menu.transition {
        left: -1px;
    }
}

@media (max-width: 1023px) {
    .ui.modal {
        width: 80% !important;
    }
}

@media (max-width: 680px) {
    .ui.modal {
        width: 85% !important;
    }

    .ui.button {
        padding: 10px !important;
    }
}
