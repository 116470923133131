@import './global.scss';

#legend {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    border-radius: 0px;
    border: 0 !important;
    bottom: -100%;
    box-shadow: 0px 4px 10px 7px rgba(12, 17, 152, 0.1);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding: 30px 50px 60px;
    position: fixed;
    transition: 1s all;
    width: 100vw;
    z-index: 8;

    .key-wrap {
        align-items: center;
        display: flex;
        flex-basis: 20%;
        flex-direction: row;
        margin: 10px 0px;
    }

    .key-wrap.item {
        .circle, .tag {
            cursor: pointer;
        }
    }

    @media (max-width: $sm-breakpoint) {
        height: 150px !important;
        overflow-x: hidden;
    }

    #legend-label .info {
        background-color: $new-blue-2;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 12.5px;

        &::before {
            position: absolute;
            transform: translateX(-50%) translateY(-53%)
        }
    }

    #legend-label .tag {
        background-color: $new-blue-2;
        color: white;
        font-weight: bold;
        margin-left: -10px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    &.showLegend {
        animation-name: showLegend;
    }

    &.hideLegend {
        animation-name: hideLegend;
    }
}

.circle {
    border-radius: 50%;
    color: white;
    flex-shrink: 0;
    height: 20px;
    text-align: center;
    width: 20px;
}

.tag {
    line-height: 25px;
    padding: 0px 10px;
    margin-top: 0px;
    font-weight: 200;
    font-size: 1.1rem !important;
    font-family: "Lexend-Deca", sans-serif !important;
}

@keyframes showLegend {
    from {
        bottom: -100%;
    }

    to {
        bottom: 0%;
    }
}

@keyframes hideLegend {
    from {
        bottom: 0%;
    }

    to {
        bottom: -100%;
    }
}

@media (max-width: 767px){
    #legend {
        padding: 0px 20px !important;
    }
}

@media (max-width: 680px) {
    #legend {
        height: calc(100% - 205px);
    }

    .key-wrap {
        flex-basis: 50% !important;
        overflow-y: auto;
    }
}
