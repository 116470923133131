.rt-timebar {
  background-color: $react-timelines-keyline-color;
}

.rt-timebar__row {
  position: relative;
  height: $react-timelines-header-row-height + $react-timelines-border-width;
  overflow: hidden;
  line-height: $react-timelines-header-row-height;
  border-bottom: $react-timelines-border-width solid $react-timelines-keyline-color;
  &:last-child {
    border-bottom-color: $react-timelines-header-separator-color;
  }
}

.rt-timebar__cell {
  position: absolute;
  text-align: center;

  background-color: $react-timelines-timebar-cell-background-color;
  border-left: 1px solid $react-timelines-keyline-color;
}

#faux-scroll-wrap {
  max-width: 100%; // Dynamically Set To PX
  top: 82px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 10px;
  position: absolute;
  display: block;
  width: 100%;
  z-index: 2;
}

#faux-scroll-inner {
  position: relative;
  width: 100%; // Dynamically Set to PX
  height: 10px;
}