@import "./global.scss";

#account-customizer {
    cursor: pointer;

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .bg-thumb {
        border: 1px solid lightgray;
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 5px 0px;
        width: 50px;
        height: 50px;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
            transition: 0.5s all;
        }

        &:nth-of-type(1) {
            background-image: url("../assets/bg_thumbnails/1.jpg") !important;
        }
        &:nth-of-type(2) {
            background-image: url("../assets/bg_thumbnails/2.jpg") !important;
        }
        &:nth-of-type(3) {
            background-image: url("../assets/bg_thumbnails/3.jpg") !important;
        }
        &:nth-of-type(4) {
            background-image: url("../assets/bg_thumbnails/4.jpg") !important;
        }
        &:nth-of-type(5) {
            background-image: url("../assets/bg_thumbnails/5.jpg") !important;
        }
        &:nth-of-type(6) {
            background-image: url("../assets/bg_thumbnails/6.jpg") !important;
        }
        &:nth-of-type(7) {
            background-image: url("../assets/bg_thumbnails/7.jpg") !important;
        }
        &:nth-of-type(8) {
            background-image: url("../assets/bg_thumbnails/8.jpg") !important;
        }
        &:nth-of-type(9) {
            background-image: url("../assets/bg_thumbnails/9.jpg") !important;
        }
        &:nth-of-type(10) {
            background-image: url("../assets/bg_thumbnails/10.jpg") !important;
        }
        &:nth-of-type(11) {
            background-image: url("../assets/bg_thumbnails/11.jpg") !important;
        }
        &:nth-of-type(12) {
            background-image: url("../assets/bg_thumbnails/12.jpg") !important;
        }
    }

    .btn-red {
        width: 100%;
        background-color: $new-blue !important;
        color: white !important;
        padding: 10px;
        border-radius: 5px !important;
        cursor: pointer;
        font-weight: 800 !important;
        letter-spacing: 1px;
        outline: none !important;
        border: 0px !important;
    }
}
