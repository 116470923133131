@import "./global.scss";

#shareModal {
  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 200px;
    margin: auto;
  }

  .content {
    overflow-x: scroll;
  }
  table {
    border-collapse: separate;
    min-width: 660px;
  }
}

.share-modal {
  // width: 280px !important;
  width: 60% !important;

  .header {
    padding-left: 35px !important;

    h5 {
      text-transform: uppercase;
      color: #484848;
    }
  }

  .content {
    padding-left: 35px !important;
    .previously-shared {
      margin-bottom: 20px;
      table {
        table-layout: fixed;
        th,
        td {
          border-right: 1px solid rgba(34, 36, 38, 0.1);

          &:last-child {
            border-right: 0px;
          }
        }

        th {
          color: $new-blue;
          padding: 5px 10px !important;

          &:first-child {
            width: 40px !important;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            width: 50px !important;
          }

          &:last-child {
            width: 60px !important;
          }

          img {
            width: 20px !important;
            height: 20px !important;
            display: block;
            margin: auto;
          }
        }

        tr {
          .delete {
            color: #ff0000;
          }

          .copy {
            color: $new-blue;
          }
        }

        .ui.checkbox {
          margin-left: 6px !important;
        }
      }
    }

    form {
      width: auto !important;

      .checkbox-wrapper {
        margin-bottom: 10px !important;
      }

      .ui.checkbox {
        font-size: 12px;
        margin-right: 20px;
      }

      .create-share {
        display: flex;
        width: 100%;

        label {
          color: #d8d8d8;
          font-size: 10px;
          margin-bottom: 5px;
        }

        button {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
      }
    }
  }
}

.menuChoice {
  align-items: center;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(127, 127, 243, 0.12);
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
  padding: 20px 30px;
  position: relative;
  transition: 0.25s all;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: $md-breakpoint) {
    width: 100%;
  }

  button {
    align-items: center;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;

    &:hover {
      background-color: transparent !important;
    }

    p,
    p:hover {
      color: rgb(39, 102, 183);
    }
  }

  .img-wrapper {
    border-radius: 50%;
    background-color: rgba(127, 127, 243, 0.12);
    width: 45px;
    padding: 10px;
    height: 45px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 25px;
    }
  }

  p {
    font-family: product-sans-bold !important;
  }
}

@media only screen and (max-width: 767px) {
  #shareModal {
    .content {
      overflow-x: scroll;
    }
    table {
      border-collapse: separate;
      min-width: 660px;
    }

    .ui.table:not(.unstackable) tbody {
      display: table-row-group !important;
    }
    .ui.table:not(.unstackable) thead {
      display: table-row-group !important;
    }

    .ui.table:not(.unstackable) tr {
      display: table-row !important;
      box-shadow: none !important;
    }
    .ui.table:not(.unstackable) tr > th {
      display: table-cell !important;
      border-right: 1px solid !important;
      border-bottom: 1px solid !important;
      border-color: #cdd2d4 !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        border-right: none !important;
      }
    }

    .ui.table:not(.unstackable) tr > td {
      display: table-cell !important;
      border-right: 1px solid !important;
      border-bottom: 1px solid !important;
      border-color: #cdd2d4 !important;
      box-shadow: none !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        border-right: none !important;
      }
    }

    .ui.table:not(.unstackable) tr:last-child {
      td {
        border-bottom: 0px !important;
      }
    }
  }
}
