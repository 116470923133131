.rt-timeline {
  position: relative;
  overflow: hidden;

  &.editing {
    margin-left: 50px; // For + element button
  }
}

.rt-timeline__header {
}

.rt-timeline__header-scroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.rt-timeline__header.rt-is-sticky {
  position: fixed;
  top: 40px !important;
  z-index: 9999;
  overflow: hidden;
}

.rt-timeline__body {
  position: relative;
  background: white;
  margin-top: 10px;
}
