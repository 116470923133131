/* Login Styles */
@import "./global.scss";

#login-container {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: fixed;
    z-index: 999;
    display: flex;

    div.flip {
        margin: auto;
        padding: 15px;

        .btn-wrap {
            display: flex;
            justify-content: space-between;

            button {
                flex-basis: calc(50% - 15px);
            }
        }
    }

    div.card {
        padding: 0px !important;
        border-radius: 0px;
        min-width: 380px;
    }

    form {
        padding: 25px;
    }

    img {
        background-color: $indigo;
        padding: 25px;
        margin-bottom: 0px !important;
        border-radius: 0px !important;
        width: 100%;
    }
}

.password-wrapper {
    margin: 0 0 1em;
    .input {
        border: 2px solid #fff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding-right: 12px;

        .icon {
            color: #fff;
            display: flex;
            align-items: center;
        }

        input {
            border: 0px !important;
        }
    }
}

@media (max-width: 680px) {
    #login-container.ui.container,
    #login-container div.card {
        max-width: 100% !important;
        width: 100% !important;
        margin: 0 auto !important;
    }
}

.auth-screen {
    display: flex;
    height: 100vh;
    color: #fff;
    background-color: $new-blue;
    overflow-y: scroll;
    .left-column {
        flex-basis: 60%;
        flex-shrink: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 80% center;
        padding: 80px;
        display: flex;
        align-items: flex-end;
        background-image: url('../assets/login-bg-large.jpg');

        &:after {
            content: '';
            background: rgba(0,0,0,0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 0;
        }

        @media (max-width: $md-breakpoint) {
            display: none;
        }

        .logo-wrapper,
        h1 {
            position: relative;
            z-index: 2;
        }

        .logo-wrapper {
            width: 300px;
        }

        h1 {
            font-size: 30px;
        }
    }

    .right-column {
        flex-basis: 40%;
        flex-shrink: 0;
        background-color: $new-blue;
        padding: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        z-index: 2;

        @media (max-width: $md-breakpoint) {
            flex-basis: 100%;
            padding: 45px;
        }

        @media (max-width: $sm-breakpoint) {
            flex-basis: 100%;
            padding: 30px;
        }

        &.password-reset {
            justify-content: center !important;
        }

        .logo-wrapper {
            width: 300px;
        }

        .middle {
            @media (max-width: $md-breakpoint) {
                width: 100%;
            }

            .title {
                font-size: 30px;
            }

            .form {
                .field {
                    label {
                        color: #fff;
                        font-size: 10px;
                    }

                    input {
                        background-color: transparent;
                        color: #fff;
                        border: 2px solid #fff;
                        border-radius: 6px;
                    }
                    ::placeholder {
                        font-style: italic;
                        color: #fff;
                    }
                }

                .remember {
                    display: flex;
                    align-items: center;
                    .ui.checkbox input:checked ~ label::after {
                        color: #2c5fd2;
                    }

                    span {
                        font-size: 12px;
                        margin-left: 7px;
                    }
                }
            }

            .btn-wrap {
                display: flex;
                flex-direction: column;
                margin-top: 30px;

                .btn-white {
                    color: #015bf5 !important;
                    background-color: #fff !important;
                    border: 2px solid #fff !important;
                    border-radius: 6px !important;
                    margin-bottom: 15px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .btn-blue {
                    background-color: #015bf5 !important;
                    color: #fff !important;
                    border: 2px solid #fff !important;
                    border-radius: 6px !important;
                    margin-bottom: 15px !important;
                }
            }

            .forgot-password {
                text-align: center;

                p {
                    color: #fff !important;
                    font-size: 10px !important;
                }
            }
        }
    }

    .privacy {
        cursor: pointer;
    }
}
