.rt-tracks {}

.rt-track-plus {
    position: absolute;
    height: 60px;
    width: 50px;
    line-height: 30px;
    font-size: 2em;
    color: white;
    background-color: #000099; // Indigo
    left: 0;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    z-index: 999;
}
