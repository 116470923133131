@import "./global.scss";

html,
body,
#root,
.App,
.App > div {
  height: 100%;
  width: 100%;
}

#home-wrapper {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.home-header {
  background-color: $new-blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 70px;
  padding: 0 100px;
  margin-bottom: 200px;

  @media (max-width: $md-breakpoint) {
    margin-bottom: 0px;
    padding: 0 20px;
  }

  .logo-wrapper {
    width: 210px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    max-width: 300px;

    button {
      background-color: transparent !important;
      border: 1px solid white !important;
      color: white !important;
      border-radius: 5px !important;

      &:hover {
        background-color: white !important;
        color: $new-blue-2 !important;

        i.icon:before {
          color: $new-blue-2 !important;
        }
      }
    }

    h4 {
      font-family: product-sans-regular !important;
      margin-bottom: 0px;
      line-height: 32px;
      margin-right: 15px;
    }
  }
}

#menuSplashBgCover,
#menuSplashBg {
  width: 100%;
  height: 100%;
}

#menuSplashBgCover {
  // position: absolute;
  // top: 0;
  // left: 0;
  // background-color: #8459f6;
  //filter: grayscale(0.6);
  //background-color: #92d669;
  //background-color: #7fdee4;
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%238459f6' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");

  //background-color: #7fdee4; //#92d669;  //#8459f6; //#5873f6;
  opacity: 0.5;
  z-index: 2;
}

#menuSplashBg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%);
  opacity: 1;
}

#splashLogo {
  max-width: 400px;
  margin: auto !important;
  display: block;
}

#menuSplash {
  display: block;
  width: 100%;
  height: calc(100% - 70px);
  position: absolute;
  top: 70px;
  left: 0px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $md-breakpoint){
    top: auto;
    left: auto;
    height: auto;
    position: relative;
  }

  // @media (max-width: 768px) and (min-width: 768px) {
  //   position: relative !important;
  //   top: 0px !important;
  //   padding-top: 50px !important;
  // }

  // @media (max-width: 767px) {
  //   height: auto !important;
  //   position: relative !important;
  //   top: 0px !important;
  //   padding-top: 50px !important;
  // }

  .icon {
    color: #100c6b;
  }

  button {
    //background-color: #5873f6;
    //background-color: #7fdee4;
    background-color: #100c6b;
    color: white;
  }

  .segment {
    box-shadow: none;
    border: 0;
    padding: 0 100px;
    background-color: transparent;

    @media (max-width: $md-breakpoint) {
      padding: 0 50px;
    }
  }

  #quote {
    @media (max-width: $md-breakpoint) {
      font-size: 21px;
      margin: 15px auto;
    }
  }

  #menuSplashGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .menuChoice {
      color: #000;
      margin: 15px;
      cursor: pointer;
      transition: 0.25s all;
      position: relative;
      display: flex;
      align-items: center;
      box-shadow: 0px 4px 10px rgba(127, 127, 243, 0.12);
      padding: 20px 30px;
      border-radius: 15px;
      background-color: white;

      @media (max-width: $md-breakpoint) {
        width: 100%;
      }

      .img-wrapper {
        border-radius: 50%;
        background-color: rgba(127, 127, 243, 0.12);
        width: 45px;
        padding: 10px;
        height: 45px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 25px;
        }
      }

      p {
        font-family: product-sans-bold !important;
      }

      &.disabled {
        transform: scale(0.8);
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .menuChoice.enabled:hover {
      transform: scale(1.1);
    }

    .menuChoice.disabled {
      display: none !important;
    }
  }
}

#quote {
  text-align: center;
  color: #484848;
  font-size: 36px;
  margin-bottom: 60px;
}

@media (max-width: 680px) {
  #splashLogo {
    height: 80px;
    max-width: 100%;
  }
}

.home-loader {
  .ui.dimmer {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }

  .ui.loader {
    color: $new-blue !important;

    &:after {
      border-color: $new-blue transparent transparent !important;
    }
  }
}
