@import "./global.scss";

.timeline-arrow {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 999;
}

#left-timeline-arrow {
  animation: left-pulse 1.25s infinite;
  left: 5%;
  transform: rotate(180deg);
}

#right-timeline-arrow {
  animation: right-pulse 1.25s infinite;
  right: 5%;
}

#navigation {
  border-radius: 0px !important;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;

  .first-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: $new-blue;
    padding: 0 100px;
    height: 75px;

    @media (max-width: 768px) {
      padding: 0 20px;
    }

    @media (max-width: 767px) {
      height: 40px;
    }

    * {
      border-color: $new-blue;
      background-color: $new-blue;
    }

    .left-column {
      display: flex;
      align-items: center;

      .arrow-wrapper {
        margin-right: 15px;
        cursor: pointer;
      }

      .details {
        display: flex;

        #logo {
          width: 150px;
        }
      }
    }

    .middle-column {
      .current-timeline {
        display: flex;
        align-items: center;
        height: 100%;

        .icon-wrapper {
          background-color: $new-blue;
          padding: 5px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 10px;

          img {
            background-color: $new-blue;
            margin-right: 15px;
          }

          h3 {
            margin-top: 0px;
            font-size: 18px;

            @media (max-width: 767px) {
              span {
                display: none;
              }
            }
          }
        }
      }
    }

    .right-column {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 200px;
      max-width: 300px;

      button {
        background-color: transparent !important;
        border: 1px solid white !important;
        color: white !important;
        border-radius: 5px !important;

        &:hover {
          background-color: white !important;
          color: $new-blue-2 !important;

          i.icon {
            background-color: white !important;
          }

          i.icon:before {
            color: $new-blue-2 !important;
          }
        }
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
      }

      h3 {
        margin: 5px 0px 0px 15px;
      }

      h4 {
        font-family: product-sans-regular !important;
        margin-bottom: 0px;
        margin-right: 15px;
        line-height: 32px;
      }
    }
  }

  .second-row {
    background-color: #f6f9fd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    height: 65px;

    @media (max-width: 810px) {
      flex-direction: column;
      height: 130px;
      align-items: flex-start;
      justify-content: space-evenly;
      padding: 7px 20px 0px;
    }

    @media (max-width: $sm-breakpoint) {
      flex-direction: row;
      align-items: start;
      height: 50px;
    }

    @media (max-width: 767px) {
      padding: 0px 20px !important;
      height: 40px !important;

      a.item button {
        background-color: transparent !important;
      }
    }

    .left-column {
      .input-wrapper {
        background-color: #fff;
        height: 32px;
        display: flex;
        align-items: center;
        padding: 7px 10px;
        border-radius: 5px !important;
        border: 1px solid $new-blue;

        @media (max-width: $md-breakpoint) {
          display: none !important;
        }

        @media (max-width: 767px) {
          display: none !important;
        }

        input {
          border: none;
          font-size: 12px;
          outline: transparent;
        }

        ::placeholder {
          opacity: 0.5;
        }

        .search.icon {
          color: rgba(0, 0, 0, 0.25);
          margin-bottom: 3px;
          cursor: pointer;

          &:hover {
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }

    .right-column {
      div {
        align-items: center;

        @media (max-width: $md-breakpoint){
          justify-content: space-between;

          &::after {
            display: none;
          }
        }
      }

      @media (max-width: 810px) {
        order: -1;
      }

      @media (max-width: $md-breakpoint) {
        width: 100%;
        justify-content: space-between;

        button {
          font-size: 0px !important;
          padding: 0px !important;
          border: 0px !important;

          i {
            font-size: 15px !important;
            line-height: 15px;
            margin: auto !important;
          }
        }

        #custom-timespan {
          border: 1px solid $new-blue;
          padding: 5px 10px !important;
          height: 32.844px;
          border-radius: 4px;

          button {
            font-size: 12px !important;
            padding-right: 10px !important;
            line-height: 15px;

            i {
              font-size: 12px !important;
              height: 12px;
              line-height: 12px;
              margin-right: 3.5px !important;
            }
          }
        }
      }

      @media (max-width: 767px) {
        width: 100% !important;

        &.space-evenly div {
          justify-content: flex-end;
        }
      }

      .ui.inverted.menu {
        background-color: transparent;

        @media (max-width: $md-breakpoint){
          a:first-of-type {
            padding-left: 0px;
          }
        }

        @media (max-width: 768px) {
          justify-content: space-between;
        }

        a:last-child {
          padding-right: 0px;
        }

        .icon {
          color: $new-blue !important;
        }

        .item {
          &:first-child {
            @media (max-width: 810px) {
              padding-left: 0px;
            }
          }
        }
      }

      .ui.selection.dropdown {
        display: flex;
        justify-content: center;

        .text {
          color: rgb(39, 102, 183);
          // text-transform: uppercase;
        }

        .alternate.horizontal.icon {
          order: -1;
        }
        .visible.menu {
          left: -1px;

          .item {
            text-align: center;
          }

          .text {
            // text-transform: uppercase;
          }
        }
      }

      div a.item button {
        background-color: white;
        border-radius: 5px !important;
        border: 1px solid $new-blue;

        &:hover i:before {
          color: #fff !important;
        }
      }

      .alert-end {
        background-color: #000 !important;
        color: #fff !important;
        border-color: #fff;
      }
    }
  }
}

#navigation.sticky {
  top: 0px;
  position: fixed;
  z-index: 9;
  left: 0px;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  animation: show-nav-fixed 1.2s ease-in-out forwards;
}

div.menu {
  padding: 0px !important;
  margin: 0px !important;
}

#navigation div.menu div.item {
  cursor: pointer;

  @media (max-width: 767px) {
    padding: 5px 10px !important;
  }
}

.ui.popup:before,
.ui.menu .item:before {
  display: none !important;
}

@keyframes show-nav-fixed {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes left-pulse {
  0% {
    transform: rotate(180deg) scale(100%) translateY(-50%);
  }
  50% {
    transform: rotate(180deg) scale(70%) translateY(-50%);
  }
  100% {
    transform: rotate(180deg) scale(100%) translateY(-50%);
  }
}

@keyframes right-pulse {
  0% {
    transform: scale(100%) translateY(-50%);
  }
  50% {
    transform: scale(70%) translateY(-50%);
  }
  100% {
    transform: scale(100%) translateY(-50%);
  }
}
