div.password-reset .password-wrapper {
  position: relative;
  
  input {
    background-color: transparent !important;
    color: white !important;
    border: 2px solid white !important;
  }

  i.icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
