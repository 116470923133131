@import "./global.scss";

#orientation-modal {
  display: none !important;
}

@media (orientation: portrait) and (max-width: 767px) {
  #orientation-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    background-color: $new-blue;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    text-align: center;
    overflow: hidden;

    #inner-orientation-modal {
      background-color: white;
      border-radius: 5px;
      padding: 15px;
      position: relative;

      i {
        font-size: 2em;
        margin: 10px;
      }

      h1 {
        margin: 0 auto;
      }
    }
  }
}
