$green: #00cc33 !important;
$cyan: #66ccff !important;
$violet: #6666ff !important;
$purple: #9933ff !important;
$indigo: #000099 !important;

// $new-blue: #3567d8;
$new-blue: #2766b7;
$new-blue-2: #14659d;

// $md-breakpoint: 768px;
$md-breakpoint: 899px;
$sm-breakpoint: 640px;
$xs-breakpoint: 480px;

@font-face {
  font-family: product-sans-bold;
  src: url(../assets/fonts/ProductSansBold.ttf);
}

@font-face {
  font-family: product-sans-bold-italic;
  src: url(../assets/fonts/ProductSansBoldItalic.ttf);
}

@font-face {
  font-family: product-sans-italic;
  src: url(../assets/fonts/ProductSansItalic.ttf);
}

@font-face {
  font-family: product-sans-regular;
  src: url(../assets/fonts/ProductSansRegular.ttf);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#login {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: fixed;
}

#weeks {
  font-size: 0.9em;
}

#days {
  font-size: 0.75em;
}

h1,
h2 {
  // font-family: product-sans-bold !important;
  font-family: "Lexend Deca", sans-serif !important;
  font-weight: bolder !important;
}

h3,
h4,
h5,
h6 {
  // font-family: product-sans-bold !important;
  font-family: "Lexend Deca", sans-serif !important;
  font-weight: bolder !important;
}

body,
button,
div,
label,
p,
span {
  // font-family: product-sans-regular !important;
  font-family: "Lexend Deca", sans-serif !important;
}

button,
.button {
  color: $new-blue !important;
  border: 2px solid #fff;
  background-color: #fff !important;
  transition: 0.1s all !important;
  border-radius: 2px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

button:hover,
.button:hover {
  color: white !important;
  background-color: $new-blue !important;
  border: 2px solid $new-blue;

  i {
    color: #fff !important;
  }
}

.green {
  color: $green;
}

.indigoBg {
  background-color: $green;
}

.ui.icon.message {
  min-height: 100px;
  height: auto;
  position: fixed;
  top: 0px;
  justify-content: center;
  z-index: 9999999;
  width: 100%;
  max-width: 380px !important;
  right: 0px;
}

.ui.modal {
  overflow: visible;
}

#errorMessage {
  color: darkred;
}

#successMessage {
  color: darkgreen;
}

@media (max-width: 480px) {
  .ui.icon.message {
    max-width: 100% !important;
  }
}

//------------------------------------------------------
//Timeline overrides
//------------------------------------------------------

//Timeline header - "Q1, Q2" section
#quarters {
  &.rt-timebar__row {
    height: 45px;
  }

  .rt-timebar__cell {
    color: #3567d8;
    font-size: 18px;
    font-weight: 800;
    line-height: 45px;
    cursor: pointer;
  }
}

//Timeline header - months
#months {
  &.rt-timebar__row {
    height: 23px;
  }
  .rt-timebar__cell {
    color: #fff;
    line-height: 23px;
    cursor: pointer;

    &:nth-of-type(odd) {
      background-color: $indigo;
    }

    &:nth-of-type(even) {
      background-color: #3567d8;
    }
  }
}

#weeks {
  &.rt-timebar__row {
    height: 45px !important;
    border-bottom: 2px solid #f6f9fd !important;
  }
  .rt-timebar__cell {
    line-height: 45px;
  }
}

#weeks {
  &.rt-timebar__row {
    height: 21px;
  }
}

#days {
  &.rt-timebar__row {
    height: 21px;
  }
}

.rt-timebar__row {
  line-height: inherit !important;
  border-bottom: 0px !important;
  // height: auto !important;
}

.rt-timebar_cell {
  font-size: 1.2em;
}

.rt-timebar__cell:first-child {
  border-left: 0px;
}

.rt-marker {
  z-index: 6;
}

.timeline-wrapper {
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: transparent !important;

  &.day-zoom .rt-marker--now {
    display: none !important;
  }
}

.rt-marker--now {
  .rt-marker__label {
    height: 25px;
    font-size: 12px;
    min-width: 0px;
    padding: 2px 8px;
  }
}

.rt-marker--pointer {
  top: 98px !important;
  // cursor: pointer;
  // z-index: 10000000000;

  .rt-marker__label {
    color: transparent;
    background-color: #fff;
    height: 25px;
    // visibility: hidden;

    &:before {
      border-top: 6px solid $new-blue !important;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      margin-left: -4px;
    }

    strong {
      color: $new-blue;
      font-size: 13px;
    }
  }
}

.rt-marker--pointer:hover {
  .rt-marker__label {
    // opacity: 1 !important;
    // visibility: visible;
  }
}

#faux-scroll-wrap {
  top: 83px;
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f6f9fd;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c3def7;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    // background: #555;
  }
}

//Where "Q1, Q2" section and months section stick
.rt-timeline__header.rt-is-sticky {
  top: 140px !important;
  animation: show-nav-fixed 1.2s ease-in-out forwards;

  @media (max-width: 767px) {
    top: 0px !important;
    animation: show-nav-fixed 0s ease-in-out forwards;
  }
}

.rt-timeline__body {
  border-top: 0px;
  margin-top: 0px;
  padding-top: 10px;
  max-height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 767px) {
    /* Testing - causes reload bug */
    max-height: none !important;
  }
}

.rt-grid {
  top: 5px !important;
  height: 100%;

  div:first-child .rt-grid__cell {
    border-left: 0px solid transparent !important;
  }
}

//Each column
.rt-grid__cell {
  border-left: 2px solid lightgray;
  top: 5px !important;
}

.rt-grid__cell:first-child {
  border-left: 0;
}

.rt-track-wrapper {
  position: relative;
}

//Each row
.rt-track__elements {
  height: 60px;
  border-bottom: 2px solid transparent; // #f6f9fd;
  transition: 0.75s all;
}

.tr-track:first-child .rt-track__elements {
  border-top: 2px solid transparent; // #f6f9fd;

  .rt-element__tooltip {
    bottom: auto !important;
    top: 50% !important;
    left: 100%;
    transition: transform 0s 0.3s;

    &:before {
      bottom: auto !important;
      top: 50% !important;
      transform: translate(-50%, -50%) rotate(90deg) !important;
      left: 0px !important;
    }
  }

  .rt-element:hover > .rt-element__tooltip,
  .rt-element:focus > .rt-element__tooltip {
    transform: translate(0px, -50%) scale(1) !important;
  }
}

.tr-track:last-child .rt-track__elements {
  border-bottom: 0;
}

//Each bar
.rt-track__element {
  top: 15px;
  height: 30px;

  .left-drag {
    position: absolute;
    width: 5px;
    cursor: ew-resize;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
    opacity: 0;
  }

  .right-drag {
    position: absolute;
    width: 5px;
    cursor: ew-resize;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 5;
    opacity: 0;
  }
}

//Plus at the side
.rt-track-plus {
  align-items: center;
  background-color: black;
  border: 2px solid #3567d8;
  color: white;
  display: flex;
  font-size: 15px;
  height: 30px;
  justify-content: flex-end;
  left: -30px;
  opacity: 0.75;
  padding: 11px;
  position: absolute;
  // transition: 0.5s all;
  width: 60px;
  z-index: 9;

  &:hover,
  .extend {
    left: 0px;
  }

  & span:first-of-type {
    border-radius: 50%;
    color: white;
    padding: 0px 5px;
    text-align: center;
    width: 20px;
  }

  & span:last-of-type {
    margin-left: 15px;
  }
}

//Marker
.rt-marker--pointer {
  color: #3567d8 !important;
  border-color: #3567d8 !important;
}

.rt-element {
  border-radius: 6px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 3px 5px !important;
  height: 30px !important;
  line-height: 30px !important;

  .rt-element__content {
    display: flex;
    justify-content: space-evenly;
  }

  .rt-element__title {
    color: #fff !important;
    font-weight: 400 !important;
  }

  .rt-element__intensity {
    position: absolute;
    right: 0;
    top: -22px;
    border: 1px solid $new-blue;
    color: $new-blue;
    width: 25px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      width: 5px;
      height: 10px;
      background-color: #fff;
      border-right: 1px solid $new-blue;
      right: -1px;
      bottom: -5px;
    }

    span {
      position: absolute;
      display: block;
      width: 7px;
      height: 1px;
      background-color: $new-blue;
      right: -2px;
      bottom: -3px;
      transform: rotate(45deg);
    }
  }

  .rt-element__tooltip {
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    box-shadow: 0 4px 16px 0 rgba(48, 96, 255, 0.2);
    padding: 13px 20px 13px 0px !important;
    min-width: 180px !important;

    &:before {
      border-top: 6px solid #fff !important;
    }

    .title {
      font-size: 12px;
      border-left: 2px solid #3567d8;
      padding-left: 15px;
      margin-bottom: 5px;
      color: #484848;
    }

    .start {
      font-size: 12px;
      padding-left: 15px;
      margin-bottom: 5px;
      border-left: 2px solid transparent;
      color: #484848;
      display: flex;
      justify-content: space-between;
      opacity: 0.5;

      strong {
        opacity: 1 !important;
      }
    }

    .end {
      font-size: 12px;
      margin-bottom: 5px;
      padding-left: 15px;
      border-left: 2px solid transparent;
      color: #484848;
      display: flex;
      justify-content: space-between;
      opacity: 0.5;

      strong {
        opacity: 1 !important;
      }
    }
  }

  //For tooltip title
  .rt-element__tooltip div > div:nth-child(1) {
    font-size: 12px;
    border-left: 2px solid #3567d8;
    padding-left: 15px;
    margin-bottom: 5px;
    color: #484848;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px !important;
  }
  //For tooltip start and end dates section
  .rt-element__tooltip div > div:nth-child(2),
  .rt-element__tooltip div > div:nth-child(3) {
    font-size: 12px;
    margin-bottom: 5px;
    padding-left: 15px;
    border-left: 2px solid transparent;
    color: #484848;
    display: flex;
    justify-content: space-between;
    opacity: 0.5;

    strong {
      opacity: 1 !important;
    }
  }
}

.timeline-wrapper {
  background-color: white;
  border: 2px solid #f6f9fd;
  // border-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: $sm-breakpoint) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.add-blue-color {
  background-color: $new-blue !important;
}

.not-show {
  opacity: 0;
}
